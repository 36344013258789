import {XHTTP} from '@/shared/xhttp';
import {UserService} from '@/xhttp';
import {Token} from './Token';
import bcrypt from 'bcryptjs';

@XHTTP(UserService, '/users')
export class User {
	
	private _id: number;
	private _email: string;
	private _firstName: string;
	private _lastName: string;
	private _phone: string;
	private _roles: string[];
	private _password: string;
	private _enable: boolean = true;
	private _id_commercial: string;
	private _id_user: string;
	private _currentToken: Token;
	private _createdAt: Date;
	
	/////////////
	// Getters //
	/////////////
	
	public get id(): number {
		return this._id;
	}
	
	public get password(): string {
		return this._password;
	}
	
	public get email(): string {
		return this._email;
	}
	
	public get firstName(): string {
		return this._firstName;
	}
	
	public get lastName(): string {
		return this._lastName;
	}

	public get fullName(): string {
		return ((this.firstName ? this.firstName : '')+' '+(this.lastName ? this.lastName : '')).trim();
	}

	public get initial(): string {
		return ((this.firstName ? this.firstName[0] : '')+(this.lastName ? this.lastName[0] : '')).trim();
	}
	
	public get phone(): string {
		return this._phone;
	}

	public get roles(): string[] {
		return this._roles;
	}

	public get displayRole(): string {
		if (this.roles.indexOf('ROLE_ADMINISTRATEUR')        !== -1) return 'Administrateur';
		if (this.roles.indexOf('ROLE_COMMERCIAL')        !== -1)return 'Commercial(e)';
		if (this.roles.indexOf('ROLE_EXPEDITION')        !== -1)return 'Expédition';
		if (this.roles.indexOf('ROLE_MONTEUR')        !== -1)return 'Monteur';
	}
	
	public get enable(): boolean {
		return this._enable;
	}

	public get idCommercial(): string {
		return this._id_commercial;
	}

	public get idUser(): string {
		return this._id_user;
	}
	
	public get currentToken(): Token {
		return this._currentToken;
	}
	
	public get createdAt(): Date {
		return this._createdAt;
	}
	
	/////////////
	// Setters //
	/////////////
	
	public set email(value: string) {
		this._email = value;
	}

	public set password(value: string) {
		this._password = value;
	}
	
	public set firstName(value: string) {
		this._firstName = value;
	}
	
	public set lastName(value: string) {
		this._lastName = value;
	}
	
	public set phone(value: string) {
		this._phone = value;
	}
	
	public set roles(value: string[]) {
		this._roles = value;
	}
	
	public set enable(value: boolean) {
		this._enable = value;
	}

	public set idCommercial(value: string) {
		this._id_commercial = value;
	}

	public set idUser(value: string) {
		this._id_user = value;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id       : this._id,
			email    : this._email,
			password : this._password ? bcrypt.hashSync(this._password, bcrypt.genSaltSync(13)) : null,
			firstName: this._firstName,
			lastName : this._lastName,
			phone    : this._phone,
			enable   : this._enable,
			roles    : this._roles,
			id_commercial: this._id_commercial,
			id_user : this._id_user,
		};
	}

	public fromJSON(data: any): this {
		this._id           = data.id;
		this._email        = data.email;
		this._firstName    = data.firstName;
		this._lastName     = data.lastName;
		this._phone        = data.phone;
		this._enable       = !!data.enable;
		this._roles        = data.roles;
		this._id_commercial    = data.id_commercial;
		this._id_user     = data.id_user;
		this._currentToken = data.currentToken ? (new Token()).fromJSON(data.currentToken) : null;
		this._createdAt    = data.createdAt ? new Date(data.createdAt) : null;
		return this;
	}
}
