















































































































	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator/index";
	import {Commercial, User, Prospection, Client} from '@/models';
	import {Emit} from 'vue-property-decorator';

	import {Expedition} from '@/models';
	
	import storeUser from '@/stores/modules/user';
	import CrudExpeditionEditDialog from "@/components/Crud/Expedition/EditDialog.vue";
	import CrudMonteurEditDialog from "@/components/Crud/Monteur/EditDialog.vue";
	import CrudCommercialEditDialog from "@/components/Crud/Commercial/EditDialog.vue";
	import CrudClientEditDialog from "@/components/Crud/Client/EditDialog.vue";
	import CrudClientCreateDialog from "@/components/Crud/Client/CreateDialog.vue";
	import { version } from '../../../package.json';
	import CrudProspectionEditDialog from "@/components/Crud/Prospection/EditDialog.vue";

	@Component({
		components: {
			CrudExpeditionEditDialog,
			CrudCommercialEditDialog,
			CrudClientEditDialog,
			CrudProspectionEditDialog,
			CrudClientCreateDialog,
			CrudMonteurEditDialog,
		}
	})

	export default class NavigationMainMenu extends Vue {

		@Stored(() => storeUser) me!: User;

		private reduce: boolean = false;
		private open: boolean = false;
		private appVersion = version;

		private items = [
			{
				route: { name: 'home' },
				title: 'Accueil',
				icon: 'mdi-home',
			},
			{
				title: 'Client',
				icon: 'mdi-account',
				grant: 'NAV_COMMERCIAL',
				children: [
					{
						route: { name: 'clients' },
						title: 'Liste des clients',
						icon: 'mdi-account-group',
						grant: 'API_GET_COMMERCIAL',
					},
					{
						title: 'Créer un client',
						icon: 'mdi-account-plus',
						grant: 'API_POST_COMMERCIAL',
						clickparameter: 'ClientAdd',
					},
					{
						route: { name: 'commercials-map' },
						title: 'Carte des clients',
						icon: 'mdi-map',
						grant: 'API_GET_COMMERCIAL',
					},
					{
						title: 'Statistiques client',
						icon: 'mdi-account-box',
						grant: 'API_GET_CLIENT',
						clickparameter: 'Client',
					},
				]
			},
			{
				title: 'Commercial',
				icon: 'mdi-cash-register',
				grant: 'NAV_COMMERCIAL',
				children: [
					{
						route: { name: 'gallery' },
						title: 'Photothèque',
						icon: 'mdi-panorama-variant',
						grant: 'API_GET_COMMERCIAL',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'prospections' },
						title: 'Liste des prospections',
						icon: 'mdi-note-outline',
						grant: 'API_GET_COMMERCIAL',
					},
					{
						title: 'Créer une prospection',
						icon: 'mdi-note-plus-outline',
						grant: 'API_GET_COMMERCIAL',
						clickparameter: 'Prospection',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'devis' },
						title: 'Liste des devis',
						icon: 'mdi-file-document-multiple',
						grant: 'API_GET_DEVIS',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'commandes' },
						title: 'Liste des commandes',
						icon: 'mdi-file-document-multiple',
						grant: 'API_GET_COMMANDE',
					},
					{
						title: 'Accès fichier',
						icon: 'mdi-folder-eye',
						grant: 'API_GET_COMMERCIAL',
						clickparameter: 'Commercial',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'chiffre_affaire' },
						title: 'Facturation',
						icon: 'mdi-file-document-multiple',
						grant: 'API_GET_COMMANDE',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'planning_fabrication' },
						title: 'Planning fabrications',
						icon: 'mdi-calendar',
						grant: 'API_GET_PLANNING_FABRICATION',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'planning_be' },
						title: 'Planning BE',
						icon: 'mdi-calendar',
						grant: 'API_GET_PLANNING_FABRICATION',
					},
				]
			},
			{
				title: 'Expédition',
				icon: 'mdi-truck-delivery',
				grant: 'NAV_EXPEDITION',
				children: [
					{
						title: 'Accès fichier',
						icon: 'mdi-folder-eye',
						grant: 'API_GET_EXPEDITION',
						clickparameter: 'Expedition',
					},
				]
			},
			{
				title: 'Monteur',
				icon: 'mdi-account-hard-hat',
				grant: 'NAV_MONTEUR',
				children: [
					{
						title: 'Accès fichier',
						icon: 'mdi-folder-eye',
						grant: 'API_GET_EXPEDITION',
						clickparameter: 'Monteur',
					},
				]
			},
			{
				title: 'Paramétrage',
				icon: 'mdi-cog',
				grant: 'NAV_PARAMETRES',
				children: [
					{
						route: { name: 'user' },
						title: 'Liste des utilisateurs',
						icon: 'mdi-account',
						grant: 'API_GET_USER',
					},
					/*{
						route: { name: 'token' },
						title: 'API Tokens',
						icon: 'mdi-key-variant',
					},*/
				]
			},
		];

		private onOpen() {
			this.open = true;
			this.reduce = false;
		}

		private onReduce() {
			if (this.open) {
				this.open = false;
			} else {
				this.reduce = true;
			}
		}

		private editExpedition: boolean = null;
		private editMonteur: boolean = null;
		private editCommercial: boolean = null;
		private editClient: boolean = null;
		private addClient: Client = null;
		private editProspection: Prospection = null;

		@Emit()
		private onCreate($parameter): void {
			switch ($parameter) {
				case 'Monteur':
					this.editMonteur = true;
    			break;
  				case 'Expedition':
					this.editExpedition = true;
    			break;
				case 'Commercial':
					this.editCommercial = true;
				break;
				case 'Client':
					this.editClient = true;
				break;
				case 'Prospection':
					let prospection = new Prospection()
					prospection.dwIDCommercial = this.me.idCommercial;
					this.editProspection = prospection;
				break;
				case 'ClientAdd':
					this.addClient = new Client;
				break;
			}
		}

	}
